<template>
  <main>
    <div class="holder" >
      <back-button/>

      <section id="donate" v-if="charity.title">
      <div class="photo">
        <img v-if="charity.resourceIds" :src="url + 'api/client/resource/' + charity.resourceIds[0]" :alt="charity.title"
             :title="charity.title" class="photo">
         <div v-if="!!charity.logoResourceId" class="label"><img :src="url + 'api/client/resource/' + charity.logoResourceId" alt=""></div>
      </div>

        <div class="info">
          <h1>{{ charity.title }}</h1>

          <template v-if="charity.target.type == 'target'">
            <div class="funds-left" v-if="charity.target.currentCost >= charity.target.fullCost">
              <div class="progress"></div>
              <div class="title">{{ $t('donate.title', {cost: charity.target.currentCost}) }}</div>
              <div class="total">{{ $t('donate.total') }}</div>
            </div>

            <div v-else class="funds-left">
              <div class="progress inprogress">
                <svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
                  <circle class="donut-hole" cx="21" cy="21" r="15.91549430918954"></circle>
                  <circle class="donut-ring" cx="21" cy="21" r="15.91549430918954"></circle>
                  <circle class="donut-segment" cx="21" cy="21" r="15.91549430918954"
                          :stroke-dasharray="(percents) + ', 100'" :stroke-dashoffset="0"></circle>
                </svg>
              </div>
              <div class="title">{{ $t('donate.title-complete', {cost: charity.target.fullCost - charity.target.currentCost}) }}</div>
              <div class="total">{{ $t('donate.total-complete', {cost: charity.target.fullCost}) }}</div>
            </div>
          </template>

          <div class="text">
            <p style="word-break: break-word; white-space: break-spaces;" v-html="charity.description"></p>
          </div>

          <div class="btn-line">
            <button v-if="charity.canPay" class="btn js-modal-link" @click="onOpenModalClick('DONATE_MONEY')">{{ $t('donate.donate') }}</button>

            <button v-if="charity.participationCost !== null && charity.participationCost > 0" class="btn js-modal-link" @click="onOpenModalClick('PARTICIPATION')">{{ $t('donate.participate', {participationCost: charity.participationCost}) }}</button>

            <a v-bind:class="{'about': true, 'about-without-margin': charity.canPay == false && charity.participationCost == null}" v-if="charity.privacyPolicy !== null" v-bind:href="charity.privacyPolicy" target="_blank">{{ $t('donate.about') }}</a></div>
        </div>
      </section>
    </div>

    <div id="modal-fadeout"></div>

  </main>
</template>

<script>
  import BackButton from '../components/layouts/BackButton';
  import { eventBus } from '@/main.js';
  import {mapActions, mapGetters} from 'vuex';
  import { baseUrl } from "../appConfig.js";

  export default {
    name: 'Donate',
    components: { BackButton },
    title: 'Donate',

    data: function() {
        return {
          url: appSettings.baseUrl,
          charity: {
            target: {
              type: 'target',
              currentCost: 0,
              fullCost: 0,
            },
            canPay: false,
            participationCost: null
          },
          percents: 0,
        };
    },

    created: async function() {
      await this.loadDonate({id: this.$route.params.id}).then((response) => {
        this.fillMeta(response.data);
        this.charity = response.data;
        this.$route.meta.title = this.$t('titles.default') + ' — ' + this.charity.title;
        this.percents = (this.charity.target.currentCost / this.charity.target.fullCost) * 100;
      });

      eventBus.$on('charityCheckout', this.checkout);

    },

    computed: {
      ...mapGetters(['isAuthenticated']),
    },

    methods: {
      ...mapActions(['loadDonate']),
      checkout: function(sum) {

      },

    createMetaTag(property, content) {
      const tmp = document.createElement("meta");
      tmp.setAttribute("property", property);
      tmp.setAttribute("content", content);
      document.head.appendChild(tmp);
    },

    fillMeta(dto) {
      document.head.querySelector("[property~=description]").remove();
      this.createMetaTag("og:title", "Karmitt - " + dto.title);
      this.createMetaTag("og:url", baseUrl);
      this.createMetaTag("og:description", dto.description);
      this.createMetaTag("description", dto.description);

      if (dto.resourceIds) {
        const img_url =
          appSettings.baseUrl + "api/client/resource/" + dto.favoriteResourceId;
        this.createMetaTag("og:image", img_url);
      }
    },



      onOpenModalClick(type) {
        let that = this;
          if (this.isAuthenticated) {
          if (type === 'DONATE_MONEY') {
              eventBus.$emit('openDonatesModal');
          }
          else if (type === 'PARTICIPATION') {
              eventBus.$emit('openParticipationModal', {participationCost: that.charity.participationCost});
          }
        } else {
          eventBus.$emit('showLoginModal');
        }
      },
    }
  };
</script>

<style scoped>
  .about-without-margin {
    margin-left: 0 !important;
  }
</style>
